import JSConfetti from 'js-confetti'

const wrapper = document.querySelector('.content-wrapper');
let toTop = document.querySelector('#toTop');
let links = document.querySelectorAll('nav a');

const jsConfetti = new JSConfetti();

toTop.addEventListener('click', function() {
  wrapper.scrollTo(0, 0);

  jsConfetti.addConfetti({
    emojis: ['🌈'],//, '⚡️', '💥', '✨', '💫', '🌸'],
  })
});


links.forEach(function(elm) {
  elm.addEventListener('mouseover', function (){
    jsConfetti.addConfetti({
      emojiSize: 128,
      confettiNumber: 2,
      //confettiRadius: 6,
      emojis: ['🌈'],//, '⚡️', '💥', '✨', '💫', '🌸'],
    })
  })
});
